import "./homeStyle.css"
import React, { useState, useRef } from "react"
import logo from "./logo.png"
import * as adres from "./adresy.js"

let port = adres.config.url

const lang = window.navigator.language
let jezyk
if (lang === "pl-PL") {
  jezyk = "polski"
} else {
  jezyk = "onucowy"
}

const Home = () => {
  const ref = useRef()

  const [utilsy, setUtilsy] = React.useState([])
  const [odbior, setOdbior] = React.useState("")
  const [przekaz, setPrzekaz] = React.useState("")
  const [zamowienia, setZamowienia] = React.useState("")

  React.useEffect(() => {
    fetch(port + "/utils", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        //console.log(response)
        if (response.ok) {
          return response.json()
        }
        throw response
      })
      .then((data) => {
        console.log(data)
        setUtilsy(data)
      })
  }, [])

  React.useEffect(() => {
    let odbiorTemp
    utilsy.find((x) => {
      if (x.id === "odbior") {
        odbiorTemp = x.value[jezyk]
      }
    })
    let przekazTemp
    utilsy.find((x) => {
      if (x.id === "przekazanie") {
        przekazTemp = x.value[jezyk]
      }
    })
    let zamowieniaTemp
    utilsy.find((x) => {
      if (x.id === "zamowienia") {
        zamowieniaTemp = x.value[jezyk]
      }
    })
    setZamowienia(zamowieniaTemp ?? "Zamówienia")
    setOdbior(odbiorTemp ?? "Odbiór pojazdu")
    setPrzekaz(przekazTemp ?? "Przekazanie pojazdu")
  }, [utilsy])

  React.useEffect(() => {
    const odbior = document.getElementById("btnOdbior-home")
    const zdanie = document.getElementById("btnZdanie-home")
    const zamowienia = document.getElementById("btnZamowienia-home")

    odbior.addEventListener("click", (e) => {
      localStorage.setItem("protokolType", 0)
      window.location.href = "/truck"
    })

    zdanie.addEventListener("click", (e) => {
      localStorage.setItem("protokolType", 1)
      //redirect to /form
      window.location.href = "/truck"
    })

    zamowienia.addEventListener("click", (e) => {
      window.location.href = "https://zamowienia.kociuk.pl"
    })
  }, [])

  return (
    <div id="id01-home" className="modal-home">
      <form
        className="modal-content-home animate-home"
        id="forma-home"
        method="post"
      >
        <div className="imgcontainer-home">
          <img className="logo-home" src={logo} alt="logo" />
        </div>
        <div className="container-home">
          <button type="button" id="btnOdbior-home" className="btn-home">
            {odbior}
          </button>
          <button type="button" id="btnZdanie-home" className="btn-home">
            {przekaz}
          </button>
          <button type="button" id="btnZamowienia-home" className="btn-home">
            {zamowienia}
          </button>
        </div>
      </form>
    </div>
  )
}

export default Home
